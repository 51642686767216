var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"scb-easy-app-modal","hide-footer":"","size":"xl","title":"ลงทะเบียนระบบออโต้ SCB Easy App"}},[_c('b-overlay',{attrs:{"show":_vm.isFetching}},[(_vm.step === 1)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendOtp)}}},[_c('ValidationProvider',{attrs:{"name":"identification","rules":"required|length:13|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"เลขบัตรประจำตัวประชาชน"}},[_c('b-form-input',{attrs:{"aria-invalid":false,"state":errors[0] ? false : null,"maxlength":"13","placeholder":"เลขบัตรประจำตัวประชาชน","type":"text"},model:{value:(_vm.registerForm.identification),callback:function ($$v) {_vm.$set(_vm.registerForm, "identification", $$v)},expression:"registerForm.identification"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"name":"day","rules":"required|length:2|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"วัน"}},[_c('b-form-input',{attrs:{"aria-invalid":false,"state":errors[0] ? false : null,"maxlength":"2","placeholder":"01 เลขวัน 2 หลัก","type":"text"},model:{value:(_vm.registerForm.day),callback:function ($$v) {_vm.$set(_vm.registerForm, "day", $$v)},expression:"registerForm.day"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"name":"month","rules":"required|length:2|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"เดือน"}},[_c('b-form-input',{attrs:{"aria-invalid":false,"state":errors[0] ? false : null,"maxlength":"2","placeholder":"01 เลขเดือน 2 หลัก","type":"text"},model:{value:(_vm.registerForm.month),callback:function ($$v) {_vm.$set(_vm.registerForm, "month", $$v)},expression:"registerForm.month"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"name":"year","rules":"required|length:4|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"ปีเกิด (ค.ศ.) "}},[_c('b-form-input',{attrs:{"aria-invalid":false,"state":errors[0] ? false : null,"maxlength":"4","placeholder":"2001 ปี ค.ศ.","type":"text"},model:{value:(_vm.registerForm.year),callback:function ($$v) {_vm.$set(_vm.registerForm, "year", $$v)},expression:"registerForm.year"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"name":"pin","rules":"required|length:6|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"พิน 6 หลัก"}},[_c('b-form-input',{attrs:{"aria-invalid":false,"state":errors[0] ? false : null,"maxlength":"6","placeholder":"123456 PIN","type":"text"},model:{value:(_vm.registerForm.pin),callback:function ($$v) {_vm.$set(_vm.registerForm, "pin", $$v)},expression:"registerForm.pin"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"name":"mobile_phone_no","rules":"required|length:10|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"เบอร์โทรศัพท์"}},[_c('b-form-input',{attrs:{"aria-invalid":false,"state":errors[0] ? false : null,"maxlength":"10","placeholder":"08XXXXXXXX เบอร์โทร","type":"text"},model:{value:(_vm.registerForm.mobilePhoneNo),callback:function ($$v) {_vm.$set(_vm.registerForm, "mobilePhoneNo", $$v)},expression:"registerForm.mobilePhoneNo"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"ml-auto",attrs:{"disabled":_vm.isFetching,"type":"submit","variant":"primary"}},[_vm._v(" ส่ง OTP ")])],1)],1)]}}],null,false,367367096)}):_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.verifyOtp)}}},[_c('ValidationProvider',{attrs:{"name":"otp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":("OTP (ref: " + _vm.pac + ")")}},[_c('b-form-input',{attrs:{"aria-invalid":false,"disabled":_vm.isFetching,"state":errors[0] ? false : null,"maxlength":"6","min":"6","placeholder":"OTP","type":"text"},model:{value:(_vm.otpForm.otp),callback:function ($$v) {_vm.$set(_vm.otpForm, "otp", $$v)},expression:"otpForm.otp"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('b-button',{attrs:{"type":"submit","variant":"success"}},[_vm._v(_vm._s(_vm.$t('buttons.submit')))]),_c('b-button',{staticClass:"ml-2",attrs:{"disabled":_vm.isFetching,"type":"button","variant":"outline-primary"},on:{"click":function (e) { return _vm.sendOtp(); }}},[_vm._v(" "+_vm._s(_vm.$t('buttons.resend'))+" ")]),_c('b-button',{staticClass:"ml-2",attrs:{"type":"button","variant":"light"},on:{"click":function($event){_vm.step = 1}}},[_vm._v(" แก้ไขข้อมูล ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }